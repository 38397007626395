<template>
    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h1 class="text-xl font-semibold leading-tight text-center tracking-wide text-gray-800 md:text-2xl">Login</h1>
        <div class="space-y-2 md:space-y-4">
            <div>
                <input type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" placeholder="Enter Email" required="" @input="$emit('on-change', { value: $event.target.value, type: 'email' })" :value="formData.email" @keyup.enter="onSubmit()" />
                <div class="text-2xs mt-1 font-thin text-red-500" :class="{ invisible: errors.formData.email.email }">Please enter valid Email Address</div>
            </div>
            <button
                :disabled="errors.formData.email.$invalid"
                type="submit"
                class="otp w-full text-white-text bg-primary hover:bg-primary-focus focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex items-center justify-center"
                @click="onSubmit()"
            >
                <Loader class="mr-2" v-if="loadingStates.otp" />
                Generate One-time password
            </button>
        </div>
    </div>
</template>

<script>
const Loader = () => import("@shared/loader");
import { getLoginInfo} from "./../../services";

export default {
    name: "login-email",
    props: {
        formData: {
            type: Object,
            default: () => {},
        },
        errors: {
            type: Object,
            default: () => {},
        },
        loadingStates: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        onSubmit() {
            if (!this.errors.formData.email.$anyError && !this.loadingStates.otp) {
                this.$emit("on-submit", { component: "loginOTP" });
            }
        },
    },
    components: {
        Loader,
    },
    mounted() {
        getLoginInfo();
    }
};
</script>

<style lang="scss" scoped></style>
